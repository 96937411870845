import React, {useState, useEffect } from 'react'
// import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Box';
// import Modal from '@mui/material/Modal';
// import { Link } from 'react-router-dom';
// import StarBorderIcon from '@mui/icons-material/StarBorder';
 
import { Grid, Typography, Paper, Box, Button } from '@mui/material';
import { loadBookChapters } from '../../../redux/actionsCreators/booksCreator';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import Loading from '../Loading/Loading';
import Container from '@mui/material/Container';
import "./style.css";
import SingleItem from '../SingleItem/SingleItem'
import CssBaseline from '@mui/material/CssBaseline';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'rgb(0 0 0 / 0.9)',
    color: 'white',
    border: '0.5px solid #000',
    boxShadow: 24,
    pt: 5,
    px: 4,
    display:'flex',
    width: {md:750, xs:500},
    height: 400,
    
};

function BookChapters() {

    let { CategoryId } = useParams();

    console.log('..categoryInfo..', CategoryId);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.auth);
    const [data,setData] = useState([])

    

    useEffect(() => {
       
            dispatch(loadBookChapters(CategoryId))
                .then((posts) => {
                    console.log(' load comments...' + JSON.stringify(posts));
                    setData(posts.value);
                })
                .catch((error) => {
                    // setAlertData(null);
                    console.log('...get post error..' + JSON.stringify(error));
                });


    
 
    }, []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return  data && data.length > 0 ? (
        <Box sx={{ backgroundColor: "silver" }}>
        <CssBaseline />
        <React.Fragment>
          <main>
  
            <Box
              sx={{
                pt: 8,
                pb: 6,
              }}
            >
   
            </Box>
            <Container sx={{ py: 8 }} maxWidth="md">
              <Grid container spacing={4}>
                {data.map((book) => (
                  <Grid item key={book.id} xs={12} sm={6} md={4}>
                    <SingleItem Databook={book} link= "/BookChapter" name= {book.chapter_name} />
                  </Grid>
                ))}
              </Grid>
            </Container>
          </main>
        </React.Fragment>
      </Box>

//         <Card
//             sx={{ height: '100%', display: 'flex', flexDirection: 'column', borderRadius: '5px' }}
//         >
//              { data.map(item =>
//             <>
//             <CardMedia
//                 width="100%"
//                 height="250px"
//                 component="img"
//                 sx={{
//                     // 16:9
//                     pt: '0%',
//                 }}
//                 image={ "https://source.unsplash.com/random" }
               
//             />
//             <CardContent sx={{ flexGrow: 1 }}>
//                 <Link to={`/BookChapter/${item.id}`} style={{ color: '#1976d2', textDecoration: 'inherit' }}><Button onClick={handleClose} size="small" >{item.chapter_name}</Button></Link>
//             </CardContent>
//  </> )}
//         </Card>
    ): (
        <Loading />
    )
}

export default BookChapters;