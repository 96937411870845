// Auth
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_UPDATE_CURRENT_USER = 'AUTH_UPDATE_CURRENT_USER';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const REGISTRATION_VERIFY_ACCOUNT = 'REGISTRATION_VERIFY_ACCOUNT';

// Messaging
export const REGISTER_MESSAGING = 'REGISTER_MESSAGING';
export const CHECK_ALERT = 'CHECK_ALERT';
export const UPDATE_ALERT = 'UPDATE_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';
export const DELETE_ALERT = 'DELETE_ALERT';

export const CREATE_HELP_ALERT = 'CREATE_HELP_ALERT';
export const CREATE_PROPERTY_ALERT = 'CREATE_PROPERTY_ALERT';

// User
export const UPDATE_USER = 'UPDATE_USER';
export const FETCH_INMATES = 'FETCH_INMATES';
export const CHECK_SUBSCRIBE = 'CHECK_SUBSCRIBE';
export const FETCH_USER_PROPERTIES = 'FETCH_USER_PROPERTIES';
export const LOGCAT = 'LOGCAT';

// Inbox
export const FETCH_QUIZ = 'FETCH_QUIZ';

export const UPDATE_QUIZ_CONTEXT = 'UPDATE_QUIZ_CONTEXT';
export const CREATE_USER = 'CREATE_USER';
export const SAVE_SCORE = 'SAVE_SCORE';

export const ACTION_LOAD_DATA = 'ACTION_LOAD_DATA';
export const ACTION_SAVE_DATA = 'ACTION_SAVE_DATA';
export const ACTION_DELETE_DATA = 'ACTION_DELETE_DATA';

export const ACTION_UPLOAD_FILE = 'ACTION_UPLOAD_FILE';