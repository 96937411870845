import React from 'react'
import {Link } from 'react-router-dom';

function ProfileUpdate() {
  return (
    <>
    <div>Profile Update</div>
    <Link to="/profile" class="fixedbtn">Profile</Link>
    </>
  )
}

export default ProfileUpdate;