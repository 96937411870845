import { SERVICE_URL } from '../../constants/variables';
import {
    ACTION_LOAD_DATA,
    ACTION_SAVE_DATA,
} from '../actionTypes';


export const loadPosts = (page, limit) => {

    return (dispatch, getState) => {
        const token = getState().auth.jwt;


        const url = `${SERVICE_URL}/uufish/posts?page=${page}&limit=${limit}`;
        console.log('...load post , url...', url);
        return dispatch({
            type: ACTION_LOAD_DATA,
            payload: fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'GET',
            })
                .then((r) => r.json())
                .then((responseData) => {
                    return responseData;
                })
                .then((responseData) => {
                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};

export const loadCommentsByPostId = (postId, page, limit) => {
    console.log('...load online game...');
    return (dispatch, getState) => {
        const token = getState().auth.jwt;

        console.log('get current user, token =' + token);
        const url = `${SERVICE_URL}/uufish/comments?postId=${postId}&page=${page}&limit=${limit}`;
        console.log('..load comment url..', url)
        return dispatch({
            type: ACTION_LOAD_DATA,
            payload: fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'GET',
            })
                .then((r) => r.json())
                .then((responseData) => {
                    return responseData;
                })
                .then((responseData) => {
                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};
export const addComment = (data) => {
    console.log('...load online game...');
    return (dispatch, getState) => {
        const token = getState().auth.jwt;

        console.log('get current user, token =' + token);
        const url = `${SERVICE_URL}/uufish/comments`;

        return dispatch({
            type: ACTION_SAVE_DATA,
            payload: fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(data),
                method: 'POST',
            })
                .then((r) => r.json())
                .then((responseData) => {
                    console.log('responseData=' + JSON.stringify(responseData));
                    return responseData;
                })
                .then((responseData) => {
                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};

export const addMoment = (data) => {
    console.log('...load online game...');
    return (dispatch, getState) => {
        const token = getState().auth.jwt;

        console.log('get current user, token =' + token);
        const url = `${SERVICE_URL}/uufish/posts`;

        return dispatch({
            type: ACTION_SAVE_DATA,
            payload: fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(data),
                method: 'POST',
            })
                .then((r) => r.json())
                .then((responseData) => {
                    console.log('responseData=' + JSON.stringify(responseData));
                    return responseData;
                })
                .then((responseData) => {
                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};

export const loadPostById = (postId) => {
    console.log('...load online game...');
    return (dispatch, getState) => {
        const token = getState().auth.jwt;

        console.log('get current user, token =' + token);
        const url = `${SERVICE_URL}/uufish/post/${postId}`;
        console.log('..load comment url..', url)
        return dispatch({
            type: ACTION_LOAD_DATA,
            payload: fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'GET',
            })
                .then((r) => r.json())
                .then((responseData) => {
                    return responseData;
                })
                .then((responseData) => {
                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};