import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "./common/pagination.jsx";
import ListGroup from "./listgroup.jsx";
import Posts from "./posts.jsx";
import { paginate } from "../utils/paginate.js";
import { api } from "../config.js";
import http from "../services/httpService.js";
import Jumotron from "./common/jumbotron.jsx";
import { useDispatch, useSelector } from 'react-redux';
import { loadPosts } from '../../redux/actionsCreators/momentsCreator';

function Dashboard() {
  // state = {
  //   allposts: [],
  //   currentPage: 1,
  //   pageSize: 4,
  //   tags: [],
  //   selectedTag: { _id: "1", name: "All Posts" },
  // };
  const dispatch = useDispatch();
  const [allposts, setAllposts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [tags, setTags] = useState([]);
  const pageSize = 10;
  const [selectedTag, setSelectedTag] = useState({ _id: "1", name: "All Posts" })
  const filtered = selectedTag._id === "1" ? allposts : this.getPosts();
  const [posts, setPosts] = useState([]);
  const user = useSelector((state) => state.auth);
  useEffect(() => {
    console.log('...start to load post..');
    dispatch(loadPosts(currentPage, pageSize)).then(resp => {
      console.log('..load post..', resp);
      setAllposts(resp.value);
    })

    // const { data: allposts } = await http.get(api.postsEndPoint);
    // const { data: tags } = await http.get(api.tagsEndPoint);

    // this.setState({
    //   allposts: [...allposts],
    //   tags: [
    //     {
    //       _id: "1",
    //       name: "All Posts",
    //     },
    //     ...tags,
    //   ],
    // });
  }, []);

  const handlePageChange = (page) => {
    // this.setState({ currentPage: page });
  };
  const handlePostDelete = (post) => { };
  const handleTagSelect = (tag) => {
    // this.setState({ selectedTag: tag, currentPage: 1 });
  };
  // getPosts() {
  //   const { allposts, selectedTag } = this.state;
  //   const filtered = [];
  //   for (let i in allposts) {
  //     const post = allposts[i];
  //     const { tags } = post;
  //     for (let j in tags) {
  //       if (tags[j].name === selectedTag.name) {
  //         filtered.push(post);
  //         break;
  //       }
  //     }
  //   }
  //   console.log(filtered);
  //   return filtered;
  // }

  // const { user } = this.props;
  // const { allposts, pageSize, currentPage, tags, selectedTag } = this.state;
  // const filtered = selectedTag._id === "1" ? allposts : this.getPosts();
  // const posts = paginate(filtered, currentPage, pageSize);
  // if (allposts.length === 0)
  //   return <p>There are no posts in the database!</p>;
  return (
    <React.Fragment>
      {/* <Jumotron /> */}
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="d-flex w-100 justify-content-between m-3">
              Showing {filtered.length} posts.
              {user && (
                <Link to="/new-post">
                  <button
                    type="button"
                    class="btn btn-success"
                    style={{ marginBottom: 20 }}
                  >
                    发布你的话题
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-11">
            <Posts posts={allposts} onDelete={handlePostDelete} />
          </div>
          <div className="col-1">
            <ListGroup
              items={tags}
              selectedTag={selectedTag}
              onTagSelect={handleTagSelect}
            />
          </div>
          <Pagination
            itemCount={filtered.length}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </React.Fragment>
  );

}

export default Dashboard;
