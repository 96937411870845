const Users = [
    {
        id: 1,
        Firstname: "AHMED",
        Secondname: "BEN HAMOUDA",
        Username: "ahmedBH",
        email: "ahmed.benhamouda98@gmail.com",
        password: "isitcom147dev",
        address: "Hammem Ghezez, Nabeul, Tunisia",
    },
    {
        id: 2,
        Firstname: "Salim",
        Secondname: "Ben Hassan",
        Username: "Salim.Writer",
        email: "Salim.98benhssan@gmail.com",
        password: "pass1e58melaibb",
        address: "Hammem Ghezez, Nabeul, Tunisia",
    },
    {
        id: 3,
        Firstname: "Bechir",
        Secondname: "Belhaj salah",
        Username: "Becho.Photographer",
        email: "BichoCA1921@infos@gmail.com",
        password: "pass158meliabb",
        address: "Hammem Ghezez, Nabeul, Tunisia",
    },
    {
        id: 4,
        Firstname: "Iheb",
        Secondname: "Benlhaj Salah",
        Username: "OussDev",
        email: "csgo1.6lol97@gmail.com",
        password: "pass158melibsb",
        address: "Hammem Ghezez, Nabeul, Tunisia",
    },
    {
        id: 5,
        Firstname: "Marwen",
        Secondname: "Ben Slim",
        Username: "Marwen_bs",
        email: "WebDev.Marwenoksuits.fr@gmail.com",
        password: "realwebdev9474",
        address: "hay al gahzela, ariana, Tunisia",
    }
]

export default Users;