const Reviews = [
    {
        id: 1,
        user_id : 2,
        review : 'great book,  but is short in number of pages (: hhhhhhhhhhhh',
        book_id : 2,
    },

    {
        id: 2,
        user_id : 2,
        review : 'Good Book, give me a lot of experience',
        book_id : 3,
    },

    {
        id: 3,
        user_id : 5,
        review : 'this is a borring book',
        book_id : 5,
    },

    {
        id: 4,
        user_id : 5,
        review : 'We I read This Books i found that is one of the best in my list',
        book_id : 7,
    },
]

export default Reviews;