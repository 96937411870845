// import AsyncStorage from '@react-native-async-storage/async-storage';

import { applyMiddleware, createStore, compose } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import promise from 'redux-promise-middleware';
import { thunk } from 'redux-thunk';
// import reactotron from '../config/reactotron';
import localForage from 'localforage';
import rootReducer from './reducers';

const persistConfig = {
   key: 'root',
   storage: localForage,
   whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
   persistedReducer,

   applyMiddleware(thunk, promise),
);
let persistor = persistStore(store);

export { store, persistor };
