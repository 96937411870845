import React, { useEffect, useState } from "react";
import Joi from "joi-browser";
import { ToastContainer, toast } from "react-toastify";
import Input from "./common/input";
import Form from "./common/form";
import { useDispatch, useSelector } from 'react-redux';
import { addMoment } from '../../redux/actionsCreators/momentsCreator'
import axiosInstance from '../../utils/axios';
import { UPLOAD_URL } from "../../constants/variables";
function NewPost() {
  const user = useSelector((state) => state.auth);

  const [data, setData] = useState({ title: "", description: "", tags: [] });
  const [errors, setErrors] = useState({ title: "", description: "", tags: [] });
  const [tags, setTags] = useState([]);
  const [content, setContent] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([])
  const [progress, setProgress] = useState()
  const [error, setError] = useState();
  const [uploadFiles, setUploadFiles] = useState([])
  const dispatch = useDispatch();
  if (!user.jwt) {
    // goto login
    window.location.replace('/login');
    return;
  }

  const schema = {
    title: Joi.string().required().min(10).label("Title"),
    description: Joi.string().required().min(5).label("Description"),
    tags: Joi.array(),
  };
  const handleTagChange = (tagID) => {
    console.log("hello");
    let data = this.state.data;
    const newtags = data.tags;
    const index = newtags.indexOf(tagID);
    if (index === -1) newtags.push(tagID);
    else newtags.splice(index, 1);
    data = {
      title: data.title,
      description: data.description,
      tags: newtags,
    };
    console.log(data);
    setData(data);
  };
  // async componentDidMount() {
  //   let tags = await http.get(api.tagsEndPoint);
  //   try {
  //     this.setState({ tags: tags.data });
  //   } catch (ex) {
  //     if (ex.response && ex.response.status === 400) {
  //       toast.error("Post Validation Failed!");
  //     }
  //   }
  // }
  const doSubmit = async () => {
    try {
      console.log('...do submit..', content);
      if (content.length < 2) {
        toast.error("请输入内容!");
        return
      }
      if (selectedFiles && selectedFiles.length > 0) {
        onFileUpload();
      } else {
        const postData = {
          userUuid: user.user.user_uuid,
          contents: content,
          images: []
        }
        dispatch(addMoment(postData)).then(resp => {
          console.log('..add moment return..', resp);
          toast.success('发布成功!');
          window.location.replace('/');
        })
      }

      // const { data } = this.state;
      // const { response } = await createpost(data);
      // console.log(response);
      // window.location = "/dashboard";
    } catch (ex) { }
  };

  const handleChange = (e) => {
    console.log('..handle change..', e.target.value);
    setContent(e.target.value);
  }
  const validate = () => {

  }
  const onFileUpload = () => {

    let formData = new FormData()
    selectedFiles.map(item => {
      console.log('..file upload..', item);
      formData.append("files", item, item.name)
    })

    //Clear the error message
    setError("")
    fetch(UPLOAD_URL + "/upload-multiple-files", {
      method: 'POST',
      body: formData,
    }).then((response) => response.json())
      .then((response) => {
        console.log('upload repsone,', response);

        const rootPath = response.rootPath.replace('/static/uploads/', '/');
        const data = response.data;
        const files = [];
        data.map(item => files.push(rootPath + item.path));
        // setUploadFiles(files);
        const postData = {
          userUuid: user.user.user_uuid,
          contents: content,
          images: files
        }
        dispatch(addMoment(postData)).then(resp => {
          console.log('..add moment return..', resp);
          toast.success('发布成功!');
          window.location.replace('/');
        })

      })
      .catch(error => {
        const { code } = error?.response?.data
        switch (code) {
          case "FILE_MISSING":
            setError("Please select a file before uploading!")
            break
          default:
            setError("Sorry! Something went wrong. Please try again later")
            break
        }
      })
  }
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="container-lg">
        <h1 className="text-center m-2">发布你的话题</h1>
        <div
          className="container m-4 p-4 rounded"
          style={{ backgroundColor: "#F1F1F1" }}
        >
          {/* <form onSubmit={doSubmit}> */}
          {/* <Input
              value={data.title}
              onChange={e => handleChange(e)}
              label="Title"
              name="title"
              type="text"
              error={errors.title}
            /> */}
          <div className="form-group">
            <label htmlFor="description">内容</label>
            <textarea
              value={content}
              onChange={e => handleChange(e)}
              name="description"
              type="description"
              id="description"
              className="form-control"
            />
            {errors.description && (
              <div className="alert-info">{errors.description}</div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="tags">图片</label>
            <br />
            <div>
              <input
                type="file"
                multiple
                accept="image/png, image/gif, image/jpeg"
                onChange={e => {
                  // setSelectedFiles(e.target.files)
                  if (e.currentTarget.files) {
                    console.log('.. e.currentTarget.files..', e.currentTarget.files);
                    const chosenFiles = Array.prototype.slice.call(e.currentTarget.files);
                    setSelectedFiles(chosenFiles);
                  }
                }}
              />
            </div>
            {/* {progress && <ProgressBar now={progress} label={`${progress}%`} />} */}
          </div>
          {/* <div className="form-group">
              <label htmlFor="tags">Related Tags</label>
              <br />
              {tags.map((tag) => (
                <React.Fragment>
                  <label className="mr-3 ml-3">
                    <input
                      key={tag._id}
                      className="form-check-input"
                      type="checkbox"
                      onChange={() => handleTagChange(tag._id)}
                    />
                    {tag.name}
                  </label>
                </React.Fragment>
              ))}
              {errors.tags && <div className="alert-info">{errors.tags}</div>}
            </div> */}
          <div className="text-center">
            <button
              className="btn btn-primary mt-4"
              onClick={doSubmit}
              disabled={validate()}
            >
              Submit
            </button>
          </div>
          {/* </form> */}
        </div>
      </div>
    </React.Fragment>
  );

}

export default NewPost;
