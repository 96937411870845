import React, { useEffect, useState } from 'react';
import { Grid, Typography, Paper, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Background1 from '../../../assets/images/background2.jpg';
import './style.css';
import { Helmet } from 'react-helmet-async';
import Container from '@mui/material/Container';
import { loadBooksCategories } from '../../../redux/actionsCreators/booksCreator';
import { useDispatch, useSelector } from 'react-redux';
import SingleItem from '../SingleItem/SingleItem'
import Loading from '../Loading/Loading';
import CssBaseline from '@mui/material/CssBaseline';
function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  useEffect(() => {

    dispatch(loadBooksCategories())
      .then((posts) => {
        console.log(' load book categories...' + JSON.stringify(posts));
        setCategories(posts.value);
      })
      .catch((error) => {
        // setAlertData(null);
        console.log('...get post error..' + JSON.stringify(error));
      });




  }, []);
  return <>

    <Helmet>
      <meta name="description" content="书籍是人类进步的阶梯" />
    </Helmet>

    <React.Fragment>

      {categories.length > 0 && loading === false ?

        (
          <Box sx={{ backgroundColor: "silver" }}>
            <CssBaseline />
            <React.Fragment>
              <main>

                <Box
                  sx={{
                    pt: 8,
                    pb: 6,
                  }}
                >

                </Box>
                <Container sx={{ py: 8 }} maxWidth="md">
                  <Grid container spacing={4}>
                    {categories.map((category) => (
                      <Grid item key={category.id} xs={12} sm={6} md={4}>
                        <SingleItem Databook={category} link="/BookCategory" name={category.categories_name} />
                      </Grid>
                    ))}
                  </Grid>
                </Container>
              </main>
            </React.Fragment>
          </Box>
        )
        :
        (
          <Typography variant="h6" color="inherit" noWrap>
            <Loading />
          </Typography>
        )
      }

    </React.Fragment>

    {/* <Paper
            sx={{
                position: 'relative',
                backgroundColor: 'grey.800',
                color: '#fff',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url(${Background1})`,
                height: "600px"
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    backgroundColor: 'rgba(0,0,0,.3)',

                }}
            />
            <Grid container>
                <Grid item md={6}>
                    <Box
                        sx={{
                            position: 'relative',
                            p: { xs: 3, md: 6 },
                            pr: { md: 0 },
                            width: { md: "150%" },

                        }}
                    >
                        <Typography sx={{ lineHeight: { md: "1.5em" }, fontSize: { md: 60 }, fontWeight: { md: "bold" } }} component="h1" variant="h3" color="inherit" gutterBottom>
                            Welcome To Our Books Library
                        </Typography>
                        <Typography sx={{ lineHeight: { md: "2em" }, fontSize: { md: 25 }, fontWeight: { md: "bold" }, ml: { md: 2 }, width: "85%" }} variant="h5" color="inherit" paragraph>
                            In our Library we offre you the ability to recommend Books, Rate, Review on Books & Sort List Of Books and Mutch More Services
                        </Typography>
                        {!localStorage.getItem('getID') ?
                            <React.Fragment>
                                <Button variant="contained" sx={{ ml: 10 }} onClick={() => { navigate('/Login') }}>Login Page</Button>

                                <Button variant="contained" sx={{ ml: 5 }} onClick={() => { navigate('/Register') }} >Register Page</Button>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Button variant="contained" sx={{ ml: 10 }} onClick={() => { navigate('/Filter') }}>Buy Book</Button>
                                <Button variant="contained" sx={{ ml: 10 }} onClick={() => { navigate('/Profile') }}>Profile</Button>
                                <Button variant="contained" sx={{ ml: 10 }} onClick={() => { navigate('/Contacts') }}>Contact US</Button>
                            </React.Fragment>
                        }
                    </Box>
                </Grid>
            </Grid>
        </Paper> */}
  </>;
}


export default Home;