import React, { useState } from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { RANDOM_PIC_URL } from '../../../constants/variables';

import "./style.css";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'rgb(0 0 0 / 0.9)',
    color: 'white',
    border: '0.5px solid #000',
    boxShadow: 24,
    pt: 5,
    px: 4,
    display: 'flex',
    width: { md: 750, xs: 500 },
    height: 400,

};

function CardBook({ Databook }) {
    const [open, setOpen] = useState(false);



    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Card
            sx={{ height: '100%', display: 'flex', flexDirection: 'column', borderRadius: '5px' }}
        >


            <Link to={Databook.has_chapter ? `/BookChapters/${Databook.id}` : `/BookChapter/${Databook.id}?from=books`} style={{ color: '#1976d2', textDecoration: 'inherit' }}> <CardMedia
                width="100%"
                height="150px"
                component="img"
                sx={{
                    // 16:9
                    pt: '0%',
                }}
                image={RANDOM_PIC_URL}

            />  </Link>
            <CardContent sx={{ flexGrow: 1 }}>
                {
                    Databook.has_chapter ?
                        <Link to={`/BookChapters/${Databook.id}`} style={{ color: '#1976d2', textDecoration: 'inherit' }}><Button onClick={handleClose} size="small" > {Databook.books_name} </Button></Link>
                        :
                        <Link to={`/BookChapter/${Databook.id}?from=books`} style={{ color: '#1976d2', textDecoration: 'inherit' }}><Button onClick={handleClose} size="small" > {Databook.books_name} </Button></Link>
                }


            </CardContent>

        </Card>
    )
}

export default CardBook;