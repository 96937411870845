import React, { useState, useEffect } from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import StarBorderIcon from '@mui/icons-material/StarBorder';

import ReactPaginate from 'react-paginate';
import { loadBookChapter, loadBook } from '../../../redux/actionsCreators/booksCreator';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from "react-router-dom";
import Loading from '../Loading/Loading';
import utils from '../../../utils/utils';
import "./style.css";


import { isMobile } from 'react-device-detect';


function BookChapter() {

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    console.log('..width..', width, '..height..', height);

    let { CategoryId } = useParams();
    const [searchParams] = useSearchParams();
    console.log(searchParams); // ▶ URLSearchParams {}
    console.log('..categoryInfo..', CategoryId);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.auth);
    const [data, setData] = useState([])
    const [currentPage, setCurrentPage] = useState(0);
    const [items, setItems] = useState([]);

    let fromChapters = true;
    if (searchParams.size > 0) {
        const from = searchParams.get("from");
        if (from && from === "books") {
            fromChapters = false;
        }
    }


    let linePerPage = 20;

    if (isMobile) {
        linePerPage = 10;

    }

    useEffect(() => {

        dispatch(fromChapters ? loadBookChapter(CategoryId) : loadBook(CategoryId))
            .then((posts) => {

                if (posts.value[0].file_format === 'txt') {
                    const bookContent = posts.value[0].content;

                    const bookContents = bookContent.split('\n');
                    const hasLineBreak = [];
                    bookContents.map(item => {
                        hasLineBreak.push(item + '\r\n')
                    })
                    console.log('..linePerPage..', linePerPage)
                    const contents = utils.paginate(hasLineBreak, linePerPage);
                    console.log('..contents..item..', contents[0]);
                    const paging = [];
                    for (var i = 1; i <= contents.length; i++) {
                        paging.push(contents[i - 1]);
                    }
                    setItems(paging);
                    setData(contents);
                }

                // setData(posts.value);
            })
        // .catch((error) => {
        //     // setAlertData(null);
        //     console.log('...get post error..' + JSON.stringify(error));
        // });




    }, []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const Items = ({ currentItems }) => {
        return (
            <>
                {currentItems &&
                    currentItems.map((item) => (
                        <div style={{ paddingBottom: 10 }}>
                            {item.map(line =>

                                <>
                                    {line}  <br />
                                </>

                            )}
                        </div>
                    ))}
            </>
        );
    }

    const PaginatedItems = ({ itemsPerPage }) => {
        // Here we use item offsets; we could also use page offsets
        // following the API or data you're working with.
        const [itemOffset, setItemOffset] = useState(0);

        // Simulate fetching items from another resources.
        // (This could be items from props; or items loaded in a local state
        // from an API endpoint with useEffect and useState)
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        const currentItems = items.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(items.length / itemsPerPage);

        // Invoke when user click to request another page.
        const handlePageClick = (event) => {
            const newOffset = (event.selected * itemsPerPage) % items.length;
            console.log(
                `User requested page number ${event.selected}, which is offset ${newOffset}`
            );
            setItemOffset(newOffset);
        };
        return (
            <>
                <Items currentItems={currentItems} />
                <ReactPaginate
                    nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="< previous"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                />
            </>
        );
    }
    return data && data.length > 0 ? (
        <Card
            sx={{ height: '100%', display: 'flex', flexDirection: 'column', borderRadius: '5px' }}
        >

            <CardContent sx={{ flexGrow: 1 }} className='bookContents'>

                <PaginatedItems itemsPerPage={1} />
            </CardContent>





        </Card>
    ) : (
        <Loading />
    )
}

export default BookChapter;