import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import "moment-timezone";
import { PersonCircle, HandThumbsUpFill } from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import http from "../services/httpService.js";
import { api } from "../config.js";
import PostReply from "./createReply.jsx";
import { useDispatch, useSelector } from 'react-redux';
import { loadPostById, addComment } from '../../redux/actionsCreators/momentsCreator';
import Typography from '@mui/material/Typography';
import Loading from '../../Components/Body/Loading/Loading';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
function PostPage() {

  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  console.log('..params..', params)
  const [post, setPost] = useState(null);
  const [replies, setReplies] = useState([]);
  const [comment, setComment] = useState(null);

  const user = useSelector((state) => state.auth);

  const spanStyle = {
    padding: '20px',
    background: '#efefef',
    color: '#000000'
  }

  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '400px'
  }

  useEffect(() => {
    console.log('...start to load post..');
    dispatch(loadPostById(params.id)).then(resp => {
      console.log('..load post by id..', resp.value);
      const postData = resp.value;
      setPost(postData.post[0]);
      setReplies(postData.comments.data)
      // setAllposts(resp.value);
    })


  }, []);
  const postComment = () => {
    const postId = params.id;
    if (comment && comment.length > 0) {
      const data = {
        postId: postId,
        userUuid: user.user.user_uuid,
        comment: comment
      }
      dispatch(addComment(data)).then(resp => {
        console.log('..post comment..', resp);
        toast.success("发布成功!");
      }).catch(ex => {

      })
    }
  }
  // async componentDidMount() {
  //   const id = this.props.match.params.id;
  //   const { data: post } = await http.get(api.postsEndPoint + id);
  //   const { data: replies } = await http.get(api.repliesEndPoint  + id);
  //   this.setState({ post: post, replies: replies });
  // }
  // const checkLike = () => {
  //   const { user } = this.props;
  //   const { post } = this.state;
  //   //console.log(user);
  //   if (user && post.upvotes && post.upvotes.includes(user._id)) return true;
  //   else return false;
  // }
  // const checkReplyLike = (id) => {
  //   const { replies } = this.state;
  //   const { user } = this.props;
  //   if (user) {
  //     for (let i in replies) {
  //       if (replies[i]._id === id) {
  //         if (replies[i].upvotes.includes(user._id)) return true;
  //       }
  //     }
  //   }
  //   return false;
  // }
  // handleUpvote = async () => {
  //   try {
  //     const { data: post } = await http.put(
  //       api.postEndPoint + "like/" + this.props.match.params.id,
  //       {}
  //     );
  //     console.log(post);
  //     this.setState({ post: post[0] });
  //   } catch (ex) {
  //     if (ex.response && ex.response.status === 400) {
  //       toast.error("You can't upvote your own post!");
  //     }
  //   }
  // };
  // handleReplyUpvote = async (id) => {
  //   try {
  //     const replies_old = [...this.state.replies];
  //     const reply_updated = await http.put(api.repliesEndPoint + "like/" + id, {});
  //     const { data: replies } = await http.get(
  //       api.repliesEndPoint + "/" + this.props.match.params.id
  //     );
  //     console.log(replies);
  //     this.setState({ replies: replies });
  //   } catch (ex) {
  //     if (ex.response && ex.response.status === 400) {
  //       toast.error("You can't upvote your own reply!");
  //     }
  //   }
  // };

  // const { post, replies } = this.state;
  // const { user } = this.props;
  return !post ?
    <>
      <React.Fragment>
        <Typography variant="h6" color="inherit" noWrap>
          <Loading />
        </Typography>
      </React.Fragment>
    </> :
    (
      <React.Fragment>
        <div>
          <ToastContainer />
          <div className="container col-lg-6 shadow-lg p-3 mt-5 bg-body rounded">
            {/* <h2>{post.title}</h2> */}
            <p className="mt-4" style={{ color: "#505050" }}>
              {post.contents}
            </p>
            {/* <div className="mt-1">
          Related Topics:
          {post.tags &&
            post.tags.map((tag) => (
              <span className="badge badge-success m-1 p-2">{tag.name}</span>
            ))}
          <div className="d-flex w-100 justify-content-between mt-3 mb-3">
            <button
              disabled={!user}
              className={
                this.checkLike()
                  ? "btn btn-primary"
                  : "btn btn-outline-primary"
              }
              onClick={this.handleUpvote}
            >
              <HandThumbsUpFill className="mr-2" />
              {(post.upvotes && post.upvotes.length) || 0}
            </button>
            <p>{post.views} Views</p>
          </div>
          <div
            class="d-flex w-100 justify-content-between"
            style={{ color: "#505050" }}
          >
            <div>
              <PersonCircle size={30} className="mr-2" />
              Posted by {(post.author && post.author.username) || 0}
            </div>
            <p class="mb-1">
              <Moment fromNow>{post.time}</Moment>
            </p>
          </div>
        </div> */}
          </div>
          <div className="container col-lg-6 shadow-lg p-3 mt-5 bg-body rounded">
            {

              post.media ?
                <div className="slide-container">
                  <Slide>
                    {post.media.map((slideImage, index) => (
                      <div key={index}>
                        <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.media_url})` }}>
                          {/* <span style={spanStyle}>{slideImage.caption}</span> */}
                        </div>
                      </div>
                    ))}
                  </Slide>
                </div> : null
            }
          </div>
          {/* {user && <PostReply id={this.props.match.params.id} />} */}
          <div className="container col-lg-6 shadow-lg p-3 mt-5 bg-body rounded">
            {replies.length} 回复
          </div>
          <div>
            {replies &&
              replies.map((reply) => (
                <div className="container col-lg-6 shadow-lg p-3 mt-3 bg-body rounded">
                  <div className="col-lg-2 pull-left ml-4 comment_user">
                    {
                      reply.avatar ?
                        <img src={reply.avatar} />
                        :
                        <PersonCircle size={30} className="mr-3" />
                    }
                    {reply.username}
                  </div>
                  <div className="col-lg-10 pull-right">{reply.post_comments}</div>
                  <div className="d-flex w-100 justify-content-between mt-3 mb-3">
                    {/* <button
                      className={
                        this.checkReplyLike(reply._id)
                          ? "btn btn-primary"
                          : "btn btn-outline-primary"
                      }
                      disabled={!user}
                      onClick={() => {
                        this.handleReplyUpvote(reply._id);
                      }}
                    >
                      <HandThumbsUpFill className="mr-2" />
                      {reply.upvotes.length}
                    </button> */}
                    <p class="mb-1">
                      <Moment fromNow style={{ color: "#505050" }}>
                        {reply.create_time}
                      </Moment>
                    </p>
                  </div>
                </div>
              ))}
          </div>
          <div className="container col-lg-6 shadow-lg p-3 mt-5 bg-body rounded">
            {
              user && user.jwt ?

                <div class="post">

                  <div class="topwrap">
                    <div class="userinfo pull-left">
                      <div class="avatar">
                        <img src="images/avatar4.jpg" alt="" />
                        <div class="status red">&nbsp;</div>
                      </div>

                      <div class="icons">
                        <img src="images/icon3.jpg" alt="" />
                        <img src="images/icon4.jpg" alt="" />
                        <img src="images/icon5.jpg" alt="" />
                        <img src="images/icon6.jpg" alt="" />
                      </div>
                    </div>
                    <div class="posttext pull-left">
                      <div class="textwraper">
                        <div class="postreply">Post a Reply</div>
                        <textarea name="reply" id="reply"
                          value={comment}
                          onChange={(e) => {
                            const value = e.target.value;
                            console.log('..comment..', value);
                            setComment(value);
                          }}
                          placeholder="Type your message here"></textarea>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <div class="postinfobot">

                    <div class="notechbox pull-left">
                      {/* <input type="checkbox" name="note" id="note" class="form-control" /> */}
                    </div>


                    <div class="pull-right postreply">
                      {/* <div class="pull-left smile"><a href="#"><i class="fa fa-smile-o"></i></a></div> */}
                      <div class="pull-left"><button type="button" onClick={postComment} class="btn btn-primary">Post Reply</button></div>
                      <div class="clearfix"></div>
                    </div>


                    <div class="clearfix"></div>
                  </div>

                </div>
                : <div>
                  <p>请登录来发布回复!</p>
                  <button type="button" class="btn btn-primary">Post Reply</button>
                </div>
            }
          </div>
        </div>
      </React.Fragment>
    );

}

export default PostPage;
