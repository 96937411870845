import { SERVICE_URL } from '../../constants/variables';
import {
    ACTION_LOAD_DATA,
    ACTION_SAVE_DATA,
} from '../actionTypes';


export const loadBooksCategories = () => {
    return (dispatch, getState) => {
        const token = getState().auth.jwt;

        console.log('get current user, token =' + token);
        const url = `${SERVICE_URL}/uufish/books-categories`;

        return dispatch({
            type: ACTION_LOAD_DATA,
            payload: fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'GET',
            })
                .then((r) => r.json())
                .then((responseData) => {
                    return responseData;
                })
                .then((responseData) => {
                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};

export const loadBooksSubCategories = (id) => {
    console.log('...load online game...');
    return (dispatch, getState) => {
        const token = getState().auth.jwt;

        const url = `${SERVICE_URL}/uufish/books-categories/${id}`;
        console.log('..load books sub categories url..', url)
        return dispatch({
            type: ACTION_LOAD_DATA,
            payload: fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'GET',
            })
                .then((r) => r.json())
                .then((responseData) => {
                    return responseData;
                })
                .then((responseData) => {
                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};
export const loadBook = (id) => {
    console.log('...load online game...');
    return (dispatch, getState) => {
        const token = getState().auth.jwt;

        const url = `${SERVICE_URL}/uufish/book/${id}`;
        console.log('..load books  url..', url)
        return dispatch({
            type: ACTION_LOAD_DATA,
            payload: fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'GET',
            })
                .then((r) => r.json())
                .then((responseData) => {
                    return responseData;
                })
                .then((responseData) => {
                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};
export const loadBooks = (id) => {
    console.log('...load online game...');
    return (dispatch, getState) => {
        const token = getState().auth.jwt;

        const url = `${SERVICE_URL}/uufish/books/${id}`;
        console.log('..load books  url..', url)
        return dispatch({
            type: ACTION_LOAD_DATA,
            payload: fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'GET',
            })
                .then((r) => r.json())
                .then((responseData) => {
                    return responseData;
                })
                .then((responseData) => {
                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};

export const loadBookChapters = (id) => {
    return (dispatch, getState) => {
        const token = getState().auth.jwt;

        const url = `${SERVICE_URL}/uufish/chapters/${id}`;
        console.log('..load books chapters url..', url)
        return dispatch({
            type: ACTION_LOAD_DATA,
            payload: fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'GET',
            })
                .then((r) => r.json())
                .then((responseData) => {
                    return responseData;
                })
                .then((responseData) => {
                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};

export const loadBookChapter = (id) => {
    return (dispatch, getState) => {
        const token = getState().auth.jwt;

        const url = `${SERVICE_URL}/uufish/chapter/${id}`;
        console.log('..load books chapter url..', url)
        return dispatch({
            type: ACTION_LOAD_DATA,
            payload: fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'GET',
            })
                .then((r) => r.json())
                .then((responseData) => {
                    return responseData;
                })
                .then((responseData) => {
                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};

export const addComment = (data) => {
    console.log('...load online game...');
    return (dispatch, getState) => {
        const token = getState().auth.jwt;

        console.log('get current user, token =' + token);
        const url = `${SERVICE_URL}/uufish/comments`;

        return dispatch({
            type: ACTION_SAVE_DATA,
            payload: fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(data),
                method: 'POST',
            })
                .then((r) => r.json())
                .then((responseData) => {
                    console.log('responseData=' + JSON.stringify(responseData));
                    return responseData;
                })
                .then((responseData) => {
                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};

export const findOnlineGame = (userType, userInfo, currentUserId) => {
    console.log('...load online game...');
    return (dispatch, getState) => {
        const token = getState().auth.token;

        const url = `${SERVICE_URL}/online-game/list?userType=${userType}&userInfo=${userInfo}&currentUserId=${currentUserId}`;

        return dispatch({
            type: ACTION_LOAD_DATA,
            payload: fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'GET',
            })
                .then((r) => r.json())
                .then((responseData) => {
                    return responseData;
                })
                .then((responseData) => {
                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};