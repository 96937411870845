import React from 'react'

import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';

import './App.css';

import { store, persistor } from './redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Router from './navigation/Router';
import {  HelmetProvider } from 'react-helmet-async';
function App() {
  return (
    <HelmetProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <React.Fragment>
          <Header />
          <Router />
          <Footer />
        </React.Fragment>
      </PersistGate>
    </Provider>
    </HelmetProvider>
  );
}

export default App;
