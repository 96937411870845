
import React from 'react'
// import Grid from '@mui/material/Grid';

// import FacebookIcon from '@mui/icons-material/Facebook';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import YouTubeIcon from '@mui/icons-material/YouTube';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AdsenseComponent from '../../Components/utils/AdsenseComponent';
import { Adsense } from '@ctrl/react-adsense';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Container, Button, AppBar, Typography, Box, Avatar, Toolbar, CssBaseline, IconButton, Menu } from '@mui/material';
function Footer() {

  const pages = [
    { name: '关于', link: "https://crutch.world/about/" },
    { name: '隐私条款', link: "https://crutch.world/privacy-policy/" },
    { name: '用户条约', link: "https://crutch.world/terms-conditions/" },
    { name: '联系我们', link: "https://crutch.world/contact-us/" },
    // { name: '游戏', link: "https://quiz.crutch.world" },
    // { name: 'H5 App', link: "https://h5app.world" },

  ];

  return (
    <React.Fragment>
      <Toolbar disableGutters>
        <Box sx={{ bgcolor: 'silver', p: 3, width: "100%", justifyContent: "center", flexGrow: 1, display: 'flex' }} component="footer">
          {/* <Adsense
        client="ca-pub-7640562161899788"
        slot="7259870550"
        style={{ display: 'block' }}
        layout="in-article"
        format="fluid"
      /> */}

          {/* <Typography variant="h6" align="center" gutterBottom>
        <a href='https://crutch.world/en/about/' target='_blank'> 关于我们 </a>
      </Typography> */}
          {pages.map((page) => (
            <Link to={page.link === 'Home' ? '/' : `${page.link}`} key={page.link} style={{ textDecoration: 'none' }}>
              <Button
                id="btnnavlink"
                //onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block', fontSize: 15, fontWeight: "bold", ml: 5, position: "relative", top: 3 }}
              >
                {page.name}
              </Button>
            </Link>
          ))}

          {/* 
      <Grid container spacing={2} sx={{width: {lg :'25%', md:'35%', sm: "50%", xs: '100%' }, m:"auto", background:"gray", borderRadius:"10px", pb:2}} >
        <Grid item >
          <IconButton>
            <FacebookIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton>
            <InstagramIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton>
            <TwitterIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton>
            <YouTubeIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton>
            <LinkedInIcon />
          </IconButton>
        </Grid>
      </Grid> */}

        </Box>
      </Toolbar>
      <Box sx={{ bgcolor: 'silver', p: 3, flexGrow: 1, display: 'flex' }} component="footer">
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          textAlign={"center"}
          component="p"
          width={"100%"}
        >
          <p> Every senior need a crutch and every younger can be a crutch </p>
          <p> Copyright © 2023 - 2024 <a href='https://crutch.world'>Crutch World</a>. All Rights Reserved</p>
        </Typography>
        <AdsenseComponent
          adClient="ca-pub-1320414067884126"
          adSlot="6839485257"
          adFormat="auto"
        ></AdsenseComponent>
      </Box>
    </React.Fragment>
  )
}

export default Footer;