import { ActionType } from 'redux-promise-middleware';
import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_UPDATE_CURRENT_USER,
  CREATE_USER
} from '../actionTypes';

const initialState = {};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${CREATE_USER}_${ActionType.Fulfilled}`:
    case `${AUTH_LOGIN}_${ActionType.Fulfilled}`:
      return {
        ...action.payload,
      };
    case `${AUTH_UPDATE_CURRENT_USER}_${ActionType.Fulfilled}`:
      return {
        ...state,
        user: action.payload,
      };
    case `${AUTH_LOGIN}_${ActionType.Rejected}`:
      return {};
    case `${AUTH_LOGIN}_${ActionType.Pending}`:
      return {
        pending: true,
      };
    case `${AUTH_LOGOUT}_${ActionType.Fulfilled}`:
      return {};
    default:
      return state;
  }
};

export default authReducer;
