import React from "react";
import { Link } from "react-router-dom";

const Posts = (props) => {
  const { posts } = props;
  console.log('..posts..', posts);
  return (
    <div className="list-post-group">
      {posts.map((post) => (
        <Link
          className="list-post-item list-group-item-action flex-column align-items-start"
          to={`/post/${post.id}`}
        >

          <div class="post" key={post.id}>
            <div class="wrap-ut pull-left">
              <div class="userinfo pull-left">
                <div class="avatar">
                  <img src={post.image} alt="" />
                  <div class="status green">&nbsp;</div>
                </div>

                <div class="icons">
                  {post.postName}
                  {/* <img src="images/icon1.jpg" alt="" /><img src="images/icon4.jpg" alt="" /> */}
                </div>
              </div>
              <div class="posttext pull-left">
                {/* <h2>10 Kids Unaware of Their Halloween Costume</h2> */}
                <p>{post.comment}</p>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="postinfo pull-left">
              <div class="comments">
                <div class="commentbg">
                  0
                  <div class="mark"></div>
                </div>

              </div>
              <div class="views"><i class="fa fa-eye"></i> </div>
              <div class="time"><i class="fa fa-clock-o"></i> {post.timeStory} </div>
            </div>
            <div class="clearfix"></div>
          </div>

          {/* <div className="d-flex w-100 justify-content-between" key={post._id}>
            <h5 className="mb-1">{post.comment}</h5>
          </div>
          <small>Created by {post.postName}</small>
          <br /> */}
          {/* <small className="overflow-hidden">{post.description}</small>
          <div className="mt-1">
            Related Topics:
            {post.tags.map((tag) => (
              <span className="badge badge-secondary m-1 p-2">{tag.name}</span>
            ))}
            <h6 className="mt-2">
              {post.upvotes.length} Likes | {post.views} Views
            </h6>
          </div> */}
        </Link>
      ))}
    </div>
  );
};

export default Posts;
