import { ActionType } from 'redux-promise-middleware';
import { FETCH_QUIZ, SAVE_SCORE, ACTION_LOAD_DATA, ACTION_UPLOAD_FILE } from '../actionTypes';


const initialState = {
  quizQuestions: [],
  recordedAnswers: [],
  numQuestions: 10,
  questionType: "",
  counter: 0,
  selectedCategory: null,
  score: 0,
  userId: 0,
  savedLanguageCode: 'en',
};

const quizReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_QUIZ}_${ActionType.Pending}`:
      return {
        ...state,
        pending: true,
      };
    case `${FETCH_QUIZ}_${ActionType.Fulfilled}`:
      return {
        messages: action.payload,
        pending: false,
      };
    case `${ACTION_UPLOAD_FILE}_${ActionType.Fulfilled}`:
    case `${ACTION_LOAD_DATA}_${ActionType.Fulfilled}`:
    case `${SAVE_SCORE}_${ActionType.Fulfilled}`:
      return {
        ...state,
        ...action.payload
      }

    case "UPDATE_USER_SCORE":
      console.log('...update score..', action.payload, '...state..', state);
      const jsonData = {
        ...state,
        ...action.payload,
        score: action.payload.score,
      };
      console.log('....update score return..', jsonData);
      return jsonData;
    default:
      return state;
  }
};

export default quizReducer;
