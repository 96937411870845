import { SERVICE_URL, UCenter_URL } from '../../constants/variables';
import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  DELETE_ACCOUNT,
  AUTH_UPDATE_CURRENT_USER,
  REGISTRATION_VERIFY_ACCOUNT,
  CREATE_USER,
  SAVE_SCORE,
  ACTION_LOAD_DATA,
  ACTION_SAVE_DATA,
  ACTION_DELETE_DATA,
  ACTION_UPLOAD_FILE
} from '../actionTypes';
// import { registerMessaging } from './messagingActionCreators';

export const loginUser = (identifier, password) => {
  return (dispatch) => {
    return dispatch({
      type: AUTH_LOGIN,
      payload: fetch(`${UCenter_URL}/auth/local`, {
        body: JSON.stringify({
          identifier: identifier,
          password,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
        .then((r) => r.json())
        .then((responseData) => {
          console.log('..user login.. response data..' + JSON.stringify(responseData));
          if (responseData.statusCode >= 300) {
            return Promise.reject(responseData);
          } else {
            return responseData;
          }
        }),
    });
  };
};

export const saveScore = (userId, score) => {
  console.log('..saving score..', userId, score);
  return (dispatch) => {
    return dispatch({
      type: SAVE_SCORE,
      payload: fetch(`${SERVICE_URL}/users/save-score`, {
        body: JSON.stringify({
          userId,
          score,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
        .then((r) => r.json())
        .then((responseData) => {
          console.log('..user login.. response data..' + JSON.stringify(responseData));
          if (responseData.statusCode >= 300) {
            return Promise.reject(responseData);
          } else {
            return responseData;
          }
        }),
    });
  };
};
export const logoutUser = () => {
  return {
    type: AUTH_LOGOUT,
    payload: new Promise((resolve) => resolve()),
  };
};
export const deleteAccount = () => {
  return (dispatch, getState) => {
    const token = getState().auth.jwt;

    return dispatch({
      type: DELETE_ACCOUNT,
      payload: fetch(`${SERVICE_URL}/residents/delete-account`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'DELETE',
      })
        .then((r) => r.json())
        .then((responseData) => {
          if (responseData.statusCode >= 300) {
            return Promise.reject(responseData);
          } else {
            // dispatch(logoutUser());
            return responseData;
          }
        }),
    });
  };
};

export const getCurrentUser = () => {
  return (dispatch, getState) => {
    const token = getState().auth.jwt;

    console.log('get current user, token =' + token);
    const url = `${SERVICE_URL}/residents/current-user`;

    return dispatch({
      type: AUTH_UPDATE_CURRENT_USER,
      payload: fetch(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'GET',
      })
        .then((r) => r.json())
        .then((responseData) => {
          console.log('responseData=' + JSON.stringify(responseData));
          return responseData;
        })
        .then((responseData) => {
          if (responseData.statusCode >= 300) {
            return Promise.reject(responseData);
          } else {
            return responseData;
          }
        }),
    });
  };
};

export const verifyAccount = (data) => {
  return {
    type: REGISTRATION_VERIFY_ACCOUNT,
    payload: fetch(`${SERVICE_URL}/public/verify`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((r) => r.json())
      .then((responseData) => {
        if (responseData.statusCode >= 300) {
          return Promise.reject(responseData);
        } else {
          return responseData;
        }
      }),
  };
};

export const createUser = (  
  username,
  email,
  password,  ) => {
  return {
    type: CREATE_USER,
    payload: fetch(`${UCenter_URL}/auth/local/register`, {
      body: JSON.stringify({
        "email": email,
        "username": username,
        "password": password,
        "isVolunteer": false,
        "phoneNumber": email.split('@')[0],
        "from_app" : "uufish_web",
        "is_developer": false
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })
      .then((r) => r.json())
      .then((responseData) => {
        console.log('...create user .. API call', responseData);
        if (responseData.statusCode >= 300) {
          return Promise.reject(responseData);
        } else {
          return responseData;
        }
      }),
  };
};





export const forgotPassword = (data) => {
  return {
    type: REGISTRATION_VERIFY_ACCOUNT,
    payload: fetch(`${UCenter_URL}/auth/forgot-password`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((r) => r.json())
      .then((responseData) => {
        if (responseData.statusCode >= 300) {
          return Promise.reject(responseData);
        } else {
          return responseData;
        }
      }),
  };
};

export const updateProfile = (userId, data) => {
  return {
    type: ACTION_SAVE_DATA,
    payload: fetch(`${SERVICE_URL}/users/update-profile/${userId}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((r) => r.json())
      .then((responseData) => {
        if (responseData.statusCode >= 300) {
          return Promise.reject(responseData);
        } else {
          return responseData;
        }
      }),
  };
};

export const saveContactUs = (data) => {
  return {
    type: ACTION_SAVE_DATA,
    payload: fetch(`${SERVICE_URL}/users/contact-us`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((r) => r.json())
      .then((responseData) => {
        if (responseData.statusCode >= 300) {
          return Promise.reject(responseData);
        } else {
          return responseData;
        }
      }),
  };
};



export const deleteMyAccount = (id) => {
  return (dispatch, getState) => {
    const token = getState().auth.jwt;

    const url = `${SERVICE_URL}/users/` + id;
    return dispatch({
      type: AUTH_LOGOUT,
      payload: fetch(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'DELETE',
      })
        .then((r) => r.json())
        .then((responseData) => {
          return responseData;
        })
        .then((responseData) => {
          if (responseData.statusCode >= 300) {
            return Promise.reject(responseData);
          } else {
            return responseData;
          }
        }),
    });
  };
}

export const setUserStatus = async (userId, status) => {
  return await fetch(`${SERVICE_URL}/users/set-status`, {
    method: 'POST',
    body: JSON.stringify({
      userId,
      status
    }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

};

export const gettUserProfileById = (id) => {
  return (dispatch, getState) => {
    const token = getState().auth.jwt;
    const url = `${UCenter_URL}/users-profiles?users_id=${id}`;
    console.log('..gettUserProfileById..', url);

    return dispatch({
      type: ACTION_LOAD_DATA,
      payload: fetch(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'GET',
      })
        .then((r) => r.json())
        .then((responseData) => {
          console.log('responseData=' + JSON.stringify(responseData));
          return responseData;
        })
        .then((responseData) => {
          if (responseData.statusCode >= 300) {
            return Promise.reject(responseData);
          } else {
            return responseData;
          }
        }),
    });
  };
};

export const updateUserProfile = (data, id) => {
  return (dispatch, getState) => {
    const token = getState().auth.jwt;
    const url = id === 0 ? `${UCenter_URL}/users-profiles` : `${UCenter_URL}/users-profiles/${id}`;

    return dispatch({
      type: ACTION_SAVE_DATA,
      payload: fetch(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
        method: id === 0 ? 'POST' : 'PUT',
      })
        .then((r) => r.json())
        .then((responseData) => {
          console.log('responseData=' + JSON.stringify(responseData));
          return responseData;
        })
        .then((responseData) => {
          if (responseData.statusCode >= 300) {
            return Promise.reject(responseData);
          } else {
            return responseData;
          }
        }),
    });
  };
};

export const uploadFiles = (fileData) => {
  return (dispatch, getState) => {
    const token = getState().auth.jwt;
    let formData = new FormData();

    formData.append("files", fileData);
    console.log('...upload file..', formData);
    return dispatch({
      type: ACTION_UPLOAD_FILE,
      payload: fetch(`${UCenter_URL}/upload`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: formData,
        method: 'POST',
      })
        .then((r) => r.json())
        .then((responseData) => {
          if (responseData.statusCode >= 300) {
            return Promise.reject(responseData);
          } else {
            return responseData;
          }
        }),
    });
  };
};

