
//local
// export const SERVICE_URL = 'http://localhost:4339/v1';
// export const UPLOAD_URL = 'http://localhost:4338';
// export const UCenter_URL = 'http://192.168.0.197:4337/api'

// live
export const SERVICE_URL = 'https://uufish-api.crutch.world/v1';
export const UCenter_URL = 'https://rest.crutch.world/api'
export const UPLOAD_URL = 'https://quiz-api.crutch.world';
export const RANDOM_PIC_URL = 'https://unsplash.it/1920/1080?random';

export const CONTACT_US_URL = 'https://blog.crutch.world/contact-us/'

export const appVersionCode = "1.1.0";
export const cacheFileName = 'local_cache.json';
export const FETCH_RESULTS = 10;
export const MAX_LENGTH = 50;
export const termsUrl = 'https://crutch.world/privacy'