const Contacts =[
    {
        id:1,
        user_id : 1,
        content : 'I d\'ont Find { In Search of Lost Time by Marcel Proust } Book Please I want It.',
        published_date : '03/10/2023',
    },

    {
        id:2,
        user_id : 1,
        content : 'i want to by { One Hundred Years of Solitude } Book Please I added to Us.',
        published_date : '03/01/2023',
    },

    {
        id:3,
        user_id : 3,
        content : 'wen i search for { War and Peace } Book can\'t find it Please I want It.',
        published_date : '07/02/2023',
    },

    {
        id:4,
        user_id : 5,
        content : 'I d\'ont Find { In Search of Lost Time by Marcel Proust } Book Please I want It.',
        published_date : '03/30/2023',
    },

    {
        id:5,
        user_id : 5,
        content : 'I d\'ont Find { The Lion, the Witch, and the Wardrobe } Book Please I want It.',
        published_date : '03/23/2023',
    },

    {
        id:6,
        user_id : 2,
        content : 'I d\'ont Find { Lord of the Rings } Book Please I want It.',
        published_date : '03/23/2023',
    },

]

export default Contacts;